import { graphql } from "gatsby"
import React from "react"
import ReactMarkdown from "react-markdown"
import Container from "@material-ui/core/Container"

const LegalTemplate = ({
  data: {
    legalData: { frontmatter },
  },
}) => {
  const { content } = frontmatter

  return (
    <Container maxWidth="lg">
      <ReactMarkdown source={content} />
    </Container>
  )
}

export default LegalTemplate

export const pageQuery = graphql`
  query LegalBySlug($slug: String!) {
    legalData: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        content
      }
    }
  }
`
